import clsx from 'clsx';
import React from 'react';

type StepProps = {
    title?: string;
    text?: string;
    button?: React.ReactElement;
    imageSrc?: string;
    imageAlt: string;
    imageLeft?: boolean;
    imageReverse?: boolean;
    children?: React.ReactNode;
    titleClassName?: string;
    descriptionClassName?: string;
    imageClassName?: string;
    textLarge?: boolean;
} & typeof defaultProps;

const defaultProps = {
    imageLeft: false,
};

function Step({
    title,
    text,
    button,
    imageSrc,
    imageAlt,
    imageLeft,
    imageReverse,
    children,
    titleClassName,
    descriptionClassName,
    imageClassName,
    textLarge,
}: StepProps) {
    return (
        <div
            className={`flex w-full lg:flex-row ${
                imageReverse ? 'flex-col-reverse' : 'flex-col'
            }`}
        >
            <div
                className={`flex flex-1 items-center justify-center ${
                    !imageLeft ? 'lg:order-first' : ''
                }`}
            >
                <div
                    className={`flex w-[calc(100%_-_60px)] flex-col  space-y-3.5 py-9 md:w-[calc(100%_-_80px)]  md:py-16 ${
                        textLarge ? 'max-w-[600px]' : 'max-w-[544px]'
                    }`}
                >
                    <h3
                        className={clsx(
                            'text-4xl font-bold uppercase leading-tight sm:leading-tight xl:text-6xl',
                            titleClassName
                        )}
                    >
                        {title}
                    </h3>
                    <p
                        className={clsx(
                            'text-base sm:text-lg',
                            descriptionClassName
                        )}
                    >
                        {text}
                    </p>
                    {children}
                    {button && button}
                </div>
            </div>
            <div className="relative order-first flex flex-1">
                <div className="w-full">
                    {imageSrc && (
                        <img
                            src={imageSrc}
                            alt={imageAlt}
                            className={
                                imageClassName ||
                                'h-auto w-full object-cover lg:h-full'
                            }
                        />
                    )}
                </div>
            </div>
        </div>
    );
}

Step.defaultProps = defaultProps;

export default Step;
