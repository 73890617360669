import { useMutation } from '@tanstack/react-query';
import { Formik } from 'formik';
import { useCookies } from 'react-cookie';
import { Helmet } from 'react-helmet';
import { useDispatch } from 'react-redux';
import LoginFormSection from '@/components/forms/signIn/SignInForm';
import useHandleSubmit from '@/hooks/useHandleSubmit';
import LoginFormModel, { loginFormSchema } from '@/models/SignInFormModel';
import { SignInParams, postSignIn } from '@/services/authService';

const OGImage = `${window.cdn}smo/SMO-homepage.png`;

export default function SignInPage() {
    const dispatch = useDispatch();
    const [cookie] = useCookies(['giftCardActivationCode']);

    const { mutateAsync: signIn } = useMutation(
        async (payload: SignInParams) => {
            const { data, status } = await postSignIn(payload);
            if (status !== 200) {
                throw Error('Password or username is incorrect');
            }
            return data;
        }
    );
    const handleSubmit = useHandleSubmit(
        loginFormSchema,
        signIn,
        () => {
            if (cookie.giftCardActivationCode) {
                window.location.href = `/gift-cards/activate/${cookie.giftCardActivationCode}`;
            } else {
                window.location.href = '/dashboard/my-deliveries';
            }
        },
        dispatch
    );

    return (
        <>
            <Helmet>
                <title>Sign in | ButcherCrowd</title>
                <meta
                    name="description"
                    content="Curated or customised meat & seafood boxes delivered for your everyday cooking essentials"
                />
                <meta property="og:title" content="Sign in | ButcherCrowd" />
                <meta
                    property="og:description"
                    content="Curated or customised meat & seafood boxes delivered for your everyday cooking essentials"
                />
                <meta property="og:image" content={OGImage} />
            </Helmet>
            <div className="flex flex-col justify-center px-6 py-24 lg:px-8">
                <h1 className="mb-8 text-4xl font-medium text-center uppercase">
                    Customer login
                </h1>
                <div className="py-8 sm:mx-auto sm:w-full sm:max-w-lg">
                    <Formik<LoginFormModel>
                        initialValues={{
                            email: '',
                            password: '',
                        }}
                        validationSchema={loginFormSchema}
                        onSubmit={handleSubmit}
                    >
                        <LoginFormSection />
                    </Formik>
                </div>
            </div>
        </>
    );
}
