import * as Sentry from '@sentry/react';
import { useMutation } from '@tanstack/react-query';
import clsx from 'clsx';
import { useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';
import { Link, useLocation } from 'react-router-dom';
import Button from '@/components/common/Button';
import { BoxIcon, HamburgerIcon, XIcon } from '@/components/common/Icons';
import useQueryPromoManager from '@/hooks/useQueryPromoManager';
import { postSignOut, useOffers } from '@/services/authService';
import { useImpactIdentify } from '@/services/dataLayer';
import { useUser } from '@/store/user/userSlice';
import Navigation from '../navigation/Navigation';
import LinkButton from './LinkButton';
import PromoBanner from './PromoBanner';
import Wrapper from './Wrapper';

const Logo2 = `${window.cdn}logo-2.png`;
const Logo3 = `${window.cdn}logo-3.png`;
const LogoWhite = `${window.cdn}logo-white.png`;

function WebHeader() {
    useQueryPromoManager();
    const [user] = useUser();
    const { offersResult } = useOffers();
    const [mobilenavOpen, setMobilenavOpen] = useState(false);
    const location = useLocation();
    const [cookies] = useCookies(['promo']);
    const { mutateAsync: signOut } = useMutation(postSignOut);
    const impactIdentify = useImpactIdentify;
    const coupon = cookies?.promo || user?.subscription?.code;
    const couponIsApplyed = coupon && coupon !== null;

    if (mobilenavOpen) {
        document.body.style.overflow = 'hidden';
    } else {
        document.body.style.overflow = 'auto';
    }

    useEffect(() => {
        impactIdentify(user.id, user.email);
        if (user.email) {
            Sentry.setUser({ email: user.email });
        }
    }, [impactIdentify, user, location]);

    return (
        <>
            {!user?.ordered && (offersResult?.length > 0 || coupon) && (
                <PromoBanner
                    coupon={coupon}
                    promoApplyed={couponIsApplyed}
                    offers={offersResult}
                />
            )}
            <div
                className={`z-50 flex w-full flex-col content-between sm:hidden ${
                    mobilenavOpen ? 'fixed top-0 bottom-0 overflow-auto' : ''
                }`}
            >
                <div className="flex h-[84px] w-full border-b-2 border-black bg-white px-4 py-5 sm:px-8">
                    <button
                        type="button"
                        onClick={() => setMobilenavOpen((o) => !o)}
                    >
                        {mobilenavOpen ? (
                            <XIcon className="w-7" />
                        ) : (
                            <HamburgerIcon />
                        )}
                    </button>
                    <div className="absolute left-0 right-0 mx-auto w-12">
                        <a href="/" className="inline-block">
                            <img
                                src={Logo3}
                                alt="ButcherCrowd"
                                className="mx-auto h-[40px]"
                            />
                        </a>
                    </div>
                    <div className="flex-1" />
                    <Button asChild secondary icon={BoxIcon}>
                        {/* eslint-disable jsx-a11y/anchor-has-content, jsx-a11y/control-has-associated-label */}
                        <a
                            href={
                                user.firstName
                                    ? '/dashboard/account'
                                    : '/get-started'
                            }
                            className="flex h-10 w-10 items-center justify-center !p-0"
                        />
                        {/* eslint-enable jsx-a11y/anchor-has-content, jsx-a11y/control-has-associated-label */}
                    </Button>
                </div>
                {mobilenavOpen && (
                    <>
                        <div className="flex flex-1 flex-col items-center bg-secondary pb-9">
                            <div className="flex flex-1 flex-col items-center space-y-5 whitespace-nowrap py-9 text-base font-medium text-white xs:space-y-6 xs:py-10 xs:text-lg">
                                <a href="/how-it-works">HOW IT WORKS</a>
                                <a href="/why-us">WHY US</a>
                                <a href="/sourcing">SOURCING</a>
                                <a href="/gift-cards">GIFT CARDS</a>
                                <a href="/faq">FAQ</a>
                                {user.firstName && (
                                    <a href="/dashboard/account">ACCOUNT</a>
                                )}
                                {user.email ? (
                                    // if user is loggued in
                                    <button
                                        type="button"
                                        onClick={async () => {
                                            await signOut();
                                            window.location.href = '/';
                                        }}
                                    >
                                        LOGOUT
                                    </button>
                                ) : (
                                    // if user is not loggued in
                                    <a href="/sign-in">SIGN IN</a>
                                )}
                            </div>

                            <div className="relative flex w-full items-end justify-center">
                                <img
                                    src={LogoWhite}
                                    alt="ButcherCrowd Logo"
                                    className="m-auto h-auto w-[120px] object-contain xs:w-[155px]"
                                />
                            </div>
                        </div>
                        <div className="w-full space-y-2 bg-primary pt-5 pb-5 text-center text-white">
                            <div className="flex w-full flex-row items-center justify-center text-[13px]">
                                <a href="/faq" className="px-3 pl-0">
                                    HELP CENTRE
                                </a>
                                <a href="/blog" className="px-3">
                                    BLOG
                                </a>
                                <a href="/delivery" className="px-3 pr-0">
                                    DELIVERY ZONE
                                </a>
                            </div>
                            <div className="flex w-full flex-row items-center justify-center text-[13px]">
                                <a
                                    href="https://www.facebook.com/ButcherCrowd/"
                                    target="_blank"
                                    rel="noreferrer"
                                    className="px-3 pl-0"
                                >
                                    FACEBOOK
                                </a>
                                <a
                                    href="https://www.instagram.com/butcher_crowd/"
                                    target="_blank"
                                    rel="noreferrer"
                                    className="px-3 pr-0"
                                >
                                    INSTAGRAM
                                </a>
                            </div>
                        </div>
                    </>
                )}
            </div>

            {/* Desktop navigation bar */}
            <div className="relative z-50 hidden border-b-4 border-b-black sm:flex">
                <Wrapper>
                    <Navigation
                        className="flex h-[90px] items-center whitespace-nowrap bg-white px-4 text-xs lg:h-[110px] lg:px-8 lg:text-base xl:h-[126px]"
                        aria-label="Main menu"
                    >
                        <ul className="flex items-center justify-end space-x-2 md:space-x-5  md:text-end lg:order-first lg:w-[350px] lg:justify-start xl:space-x-7">
                            <li>
                                <Navigation.Link asChild>
                                    {({ focused }) => (
                                        <a
                                            href="#main"
                                            className={clsx(
                                                'fixed top-4 left-4 block bg-secondary px-5 py-3 text-sm font-medium uppercase tracking-wider text-white outline-none ring-2 ring-primary',
                                                !focused && 'hidden'
                                            )}
                                        >
                                            Skip to content
                                        </a>
                                    )}
                                </Navigation.Link>
                            </li>
                            <li className="!ml-0">
                                <Navigation.Link asChild>
                                    <LinkButton asChild>
                                        <Link to="/how-it-works">
                                            How it works
                                        </Link>
                                    </LinkButton>
                                </Navigation.Link>
                            </li>
                            <li>
                                <Navigation.Link asChild>
                                    <LinkButton asChild>
                                        <Link to="/why-us">Why us</Link>
                                    </LinkButton>
                                </Navigation.Link>
                            </li>
                            <li>
                                <Navigation.Link asChild>
                                    <LinkButton asChild>
                                        <Link to="/sourcing">Sourcing</Link>
                                    </LinkButton>
                                </Navigation.Link>
                            </li>
                        </ul>
                        <ul className="order-first flex grow lg:justify-center lg:px-7">
                            <li>
                                <Navigation.Link
                                    href="/"
                                    className="inline-block focus:outline-none focus-visible:ring focus-visible:ring-primary"
                                >
                                    <img
                                        src={Logo2}
                                        alt="ButcherCrowd"
                                        className="mx-auto hidden object-contain lg:block lg:h-[46px] xl:h-[56px]"
                                    />
                                    <img
                                        src={Logo3}
                                        alt="ButcherCrowd"
                                        className="block h-[42px] w-[37px] object-contain lg:hidden"
                                    />
                                </Navigation.Link>
                            </li>
                        </ul>
                        <ul className="ml-2 flex items-center justify-end space-x-2 md:ml-7 md:space-x-5 lg:ml-11 lg:w-[350px] lg:space-x-6 xl:ml-7">
                            <li>
                                <Navigation.Link asChild>
                                    <LinkButton asChild>
                                        <Link to="/gift-cards">Gift cards</Link>
                                    </LinkButton>
                                </Navigation.Link>
                            </li>
                            <li>
                                <Navigation.Link asChild>
                                    <LinkButton asChild>
                                        <Link to="/faq">FAQ</Link>
                                    </LinkButton>
                                </Navigation.Link>
                            </li>
                            {user.email ? (
                                // if user is loggued in
                                <>
                                    <li>
                                        <Navigation.Link asChild>
                                            <LinkButton asChild>
                                                <button
                                                    type="button"
                                                    onClick={async () => {
                                                        await signOut();
                                                        window.location.href =
                                                            '/';
                                                    }}
                                                >
                                                    Logout
                                                </button>
                                            </LinkButton>
                                        </Navigation.Link>
                                    </li>
                                    <Button asChild secondary icon={BoxIcon}>
                                        {/* eslint-disable jsx-a11y/anchor-has-content, jsx-a11y/control-has-associated-label */}
                                        <a
                                            href={
                                                user.firstName
                                                    ? '/dashboard/my-deliveries'
                                                    : '/get-started'
                                            }
                                            className="flex h-10 w-10 items-center justify-center !p-0"
                                        />
                                        {/* eslint-enable jsx-a11y/anchor-has-content, jsx-a11y/control-has-associated-label */}
                                    </Button>
                                </>
                            ) : (
                                // if user is not loggued in
                                <>
                                    <li>
                                        <Navigation.Link asChild>
                                            <LinkButton asChild>
                                                <a href="/sign-in">Sign in</a>
                                            </LinkButton>
                                        </Navigation.Link>
                                    </li>
                                    <li>
                                        <Navigation.Link asChild>
                                            <Button asChild secondary>
                                                <Link
                                                    to="/get-started"
                                                    className="hidden xl:block"
                                                >
                                                    Fill your box
                                                </Link>
                                            </Button>
                                        </Navigation.Link>
                                        <Navigation.Link asChild>
                                            <Button asChild secondary size="xs">
                                                <Link
                                                    to="/get-started"
                                                    className="xl:hidden"
                                                >
                                                    Fill your box
                                                </Link>
                                            </Button>
                                        </Navigation.Link>
                                    </li>
                                </>
                            )}
                        </ul>
                    </Navigation>
                </Wrapper>
            </div>
        </>
    );
}

export default WebHeader;
